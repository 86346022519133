import React, {useState, useEffect} from "react";
import {Box, useBreakpointValue,Flex, HStack, Text, Link, ButtonGroup} from "@chakra-ui/react";
import {Link as RouterLink} from "react-router-dom"
import {IconButton} from '@mui/material'
import Typography from '@mui/material/Typography';
import GithubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import XIcon from '@mui/icons-material/X'



const Footer = () => {

// Creating a visible up icon upon scroll up
let [scrollingUp, setScrollingUp] = useState(true)
let [initialScroll, setInitialscroll] = useState(0)
  
    useEffect((e) => {

      let handleScroll = () => {
        let verticalScroll = window.scrollY
        
        setScrollingUp(verticalScroll < initialScroll)
        setInitialscroll(verticalScroll)
      }
      
      document.addEventListener("scroll", handleScroll)
      
      return () => {
        document.removeEventListener("scroll", handleScroll)
      }
    })


    // function Copyright() {
    //   return (
    //     <Typography variant="body2" sx={{ color: 'text.secondary', mt: 1 }}>
    //       {'Copyright © '}
    //       <Link color="text.secondary" href="https://mui.com/">
    //         Sitemark
    //       </Link>
    //       &nbsp;
    //       {new Date().getFullYear()}
    //     </Typography>
    //   );
    


  
    
    let mobileView = useBreakpointValue({
      base: true,
      md: false
    })
  

  
    let style = {
    position: "fixed",
    bottom: "0",
    marginBottom: mobileView? 20 :10
  }




  
  return (
    <Box backgroundColor="#18181b" maxWidth="full"  pb={10} >
      <footer>
      <Text flex={!mobileView&& "1"} mb={0}  p={4} textAlign="center" fontSize={!mobileView&& "lg"} color="white" >Powered by <Link href="https://benasenso.com" isExternal _active={{bg: "rgba(34,167,240,0.5)", color: "red"}}>Ben</Link></Text> 

        <Flex
          //margin="0 auto"
          px={ mobileView? 4:12}
          color="white"
          //justifyContent="center"
          justifyContent="space-between"
          align="center"   //new
          //alignItems="center"
          //maxWidth="1024px"
          height={16}
          paddingTop={10}
          paddingBottom={7}
        >

         {/*Empty box with flex=1 to help balance the alignment. The flex=1 makes the box takes the available space equal to 1/3. Just like 1fr in grid. NB: flex=1 is a shorthand of flex-grow, flex-shrink, flex-basis all in one */}
        <Box flex={mobileView?"2":"1"}  >
          <HStack gap={mobileView? 6:10}  mb={4} mt={7} fontSize="sm"  style={{color:"white"}}>

          <Text >
          <Link as={RouterLink} to="/privacy-policy"  >Privacy Policy</Link>  
          </Text>

          {/* <Typography sx={{ display: 'inline',  opacity: 0.5, mx:-2,  mt:1  }}>
            &nbsp;•&nbsp;
          </Typography> */}

          <Text >
          <Link >Terms of Service</Link>  
          </Text>
        
          </HStack>



        <Text fontSize="sm" mt={-5} >{'Copyright © '}
          <Link href="https://blogai.benasenso.com/" >AI Blog Generator</Link>
        </Text>
        </Box>

        {/*  <p>Ben • © 2023</p> */}
        {/* <Text flex={!mobileView&& "1"}  p={4} textAlign="center" fontSize={!mobileView&& "lg"} color="white" >Powered by <Link href="https://benasenso.com" isExternal _active={{bg: "rgba(34,167,240,0.5)", color: "red"}}>Ben</Link></Text> */}
        
        <Box flex="1" fontSize={mobileView&& "x-small"}  textAlign="right"  mt={-4}  >
        {/* <Text as={RouterLink}   to="/privacy-policy" color="white" _hover={{color:"#F4CE14"}} >Privacy Policy</Text> */}
        <ButtonGroup>
        <IconButton color="inherit" size="small" href="https://github.com/asenso95" aria-label="GitHub" sx={{ alignSelf: 'center', color:"white" }} target="_blank" rel="noopener noreferrer">
          <GithubIcon />
        </IconButton>

        <IconButton color="inherit" size="small" href="https://x.com/ararat_ben" aria-label="X" sx={{ alignSelf: 'center', color:"white"}} target="_blank" rel="noopener noreferrer" >
          <XIcon />
        </IconButton>

        <IconButton color="inherit" size="small" href="https://www.linkedin.com/in/benasenso/" aria-label="LinkedIn" sx={{ alignSelf: 'center', color:"white" }} target="_blank" rel="noopener noreferrer" >
          <LinkedInIcon />
        </IconButton>
        </ButtonGroup>

        </Box>
        

        </Flex>
        
        {(scrollingUp && window.scrollY>20) &&
        <Box box-shadow="md"  >
        <HStack justifyContent="end" paddingRight={20} >


      <div class="iconbutton">
        <a onClick={(e)=>{
          window.scrollTo({
            top: 0,
            behaviour: true
          })

        }}>

        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" width="63px">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15 11.25l-3-3m0 0l-3 3m3-3v7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        
        </a>
      </div>


   {/*     <IconButton icon={<FontAwesomeIcon icon={faAngleUp} cursor="pointer" size="2x" color="blue" />} border="3px solid blue" isRound={true} zIndex={1} onClick={(e) => {
            let element = document.getElementById("homepage-section")
            if(element){
              return element.scrollIntoView({
                behaviour:"smooth",
                block: "start"
              })
            }
            else{
              return;
            }
          }} style={style}></IconButton>  
    */}

        </HStack>
        </Box>}


      </footer>

  
    </Box>

    
  );
};
export default Footer;
