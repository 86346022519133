import Httprequest from "./CompoForDjango/Httprequest"
import Header from "./CompoForDjango/Header"
import Welcome from "./CompoForDjango/Welcome"
import AllBlogs from "./CompoForDjango/AllBlogs"
import BlogDetails from "./CompoForDjango/BlogDetails"

import Signin from "./CompoForDjango/Signin"
import Signup from "./CompoForDjango/Signup"
import Password from "./CompoForDjango/PasswordReset"
import PasswordConfirm from "./CompoForDjango/PasswordConfirm"
import ActivateEmail from "./CompoForDjango/ActivateEmail"

import NotFoundPage from "./CompoForDjango/NotFoundPage"
import PrivacyPolicy from "./CompoForDjango/PrivacyPolicy"
import Footer from "./CompoForDjango/Footer"
import { ChakraProvider } from "@chakra-ui/react"
import { createTheme, ThemeProvider } from "@mui/material";
import { Routes, Route } from "react-router-dom"
import { useEffect, useState } from "react"
import MyContextProvider from "./CompoForDjango/Context/AuthContext"
import ProtectedRoute from "./CompoForDjango/Components/ProtectedRoute"
import { useNavigate,useLocation } from "react-router-dom"
import { ToastContainer } from "react-toastify"




function App(){

    let theme = createTheme()


    let [selected, setSelected] = useState("Signin")

    let [successLogin, setsuccessLogin] = useState(false)
    let [token, setToken] = useState()
    let [blogId, setBlogId] = useState()




    useEffect((e)=>{

        let userAuthtoken = localStorage.getItem("UserAuthtoken")
        let parseUserData = JSON.parse(userAuthtoken)
        setToken(parseUserData)
        
        //   if(location.pathname === "/dashboard" || location.pathname === "/all-blogs" || location.pathname.startsWith("/blog-details") && successLogin === false ){
        //         navigate("/signin")
        //         setsuccessLogin(false)
        //     }
     
    }, [])

    return(
        <div>
            <ThemeProvider theme={theme}>
            <ChakraProvider>

            <MyContextProvider>

            <ToastContainer theme="dark" closeOnClick />
            
            {/* <AppAppBar successLogin={successLogin} setsuccessLogin={setsuccessLogin} selected={selected} setSelected={setSelected} /> */}
            <Header successLogin={successLogin} setsuccessLogin={setsuccessLogin} selected={selected} setSelected={setSelected}/>

            <Routes>
                <Route path="/" element={<Signin successLogin={successLogin} setsuccessLogin={setsuccessLogin} selected={selected} setSelected={setSelected}  />}  />         
                <Route path="/signin" element={<Signin successLogin={successLogin} setsuccessLogin={setsuccessLogin} selected={selected} setSelected={setSelected} />}  />
                <Route path="/signup" element={<Signup />} />
                <Route path="/users/password/new" element={<Password />} />
                <Route path="/password/reset/confirm/:uid/:token" element={<PasswordConfirm />} />    {/* Will be expecting uid and token in the path name */}
                <Route path="/activate/:uid/:token" element={<ActivateEmail />} />      {/* Will be expecting uid and token in the path name */}
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />      
                <Route path="*" element={<NotFoundPage />} />

            <Route path="/dashboard" element={
                <ProtectedRoute>
                <Welcome successLogin={successLogin} setsuccessLogin={setsuccessLogin} />
                </ProtectedRoute> } />
            <Route path="/all-blogs" element={
                <ProtectedRoute>
                <AllBlogs successLogin={successLogin} setsuccessLogin={setsuccessLogin} blogId={blogId} setBlogId={setBlogId} />
                </ProtectedRoute> } />
            <Route path="/blog-details/:id" element={
                <ProtectedRoute>
                <BlogDetails successLogin={successLogin} setsuccessLogin={setsuccessLogin} blogId={blogId} setBlogId={setBlogId} />
                </ProtectedRoute> } />        {/* Will be expecting id in the path name */}
            
            </Routes>
            
            <Footer />
            </MyContextProvider>

            </ChakraProvider>
            </ThemeProvider>
            
        </div>

    )
}


export default App